<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Privacy Policy <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0 terms">
                        <p>MetaFin Consulting, LLC completely understands the importance of customer information and respects the privacy of its customers. It takes all possible measures to safeguard all personal information (sometimes referred to as Personally Identifiable Information or PII) collected by it.</p>
                        <p class="mt25 mb0"><b>Collection &amp; Use of PII:</b></p>
                        <p>In process of offering our services such as Taxation Services, MetaFin Consulting, LLC shall collect several types of information including Sensitive information like Date of Birth, Social Security Number, Bank account information, Income and expense details, etc. Apart from this, we may also gather information like User IP address, browser type, etc for security and such other reasons. By registering and/or submitting personal information on our website, you are also agreeing to the use of this information in accordance with this Privacy Statement. Your personal information is not used for other purposes, unless we obtain your permission, or unless otherwise required or permitted by law or professional standards.</p>
                        <p class="mt25 mb0"><b>Sharing and Transfer of PII:</b></p>
                        <p>We do not share personal information with unaffiliated third parties, except as necessary for our legitimate professional and business needs, to carry out your requests, and/or as required or permitted by law or professional standards. In some instances, MetaFin Consulting, LLC may also share PII about you with various outside companies or service providers or vendors working on our behalf to help fulfill your requests.</p>
                        <p>In this regard, kindly note that MetaFin Consulting, LLC have Knowledge centers outside of United States of America (mainly India) and there is possibility that the information provided by you may be used by the Employees/Affiliates/Partners working from such offices in the process of fulfilling your service request. MetaFin Consulting, LLC takes all necessary precautions to maintain Security Standards not only in United States of America but also in such other countries. MetaFin Consulting, LLC may also store PII in a jurisdiction other than where you are based. By providing PII on MetaFin Consulting, LLC web site or through any other modes, users are consenting to this transfer and/or storage of their data across borders.</p>
                        <p class="mt25 mb0"><b>Children:</b></p>
                        <p>MetaFin Consulting, LLC understands the importance of protecting children's information, especially in an online environment. This privacy statement is not intended for use by children and we do not knowingly collect or maintain information about children, except as part of an engagement to provide professional services.</p>
                        <p class="mt25 mb0"><b>Date Security &amp; Integrity:</b></p>
                        <p>MetaFin Consulting, LLC has reasonable technical and organizational Security measures in place to protect personal information against unauthorized disclosure or access and against any other unlawful forms of processing. However, these measures are not absolute. In addition, the transmission of data over the Internet by its nature entails the use of systems under the control of third parties, and as a result MetaFin Consulting, LLC cannot ensure the security of such systems.</p>
                        <p class="mt25 mb0"><b>Changes to this policy:</b></p>
                        <p>MetaFin Consulting, LLC may modify this Privacy Statement from time to time to reflect our current privacy policies and practices. We encourage you to periodically review this Privacy Statement to be informed about how MetaFin Consulting, LLC is protecting your information.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
