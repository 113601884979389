<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Terms & Conditions <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0 terms">
                        <p>We thank you for showing interest in using our Services. Before proceeding further, we request you to
                            kindly take a note of the following Terms and Conditions which are important in the course of
                            professional relationship between MetaFin Consulting, LLC (Service Provider) and you (as Client). Based
                            on said terms and conditions, our services are engaged.</p>

                        <p>These terms are in relation to all our services including the U.S. Individual Income Tax Return
                            Preparation, Planning and Filing Services offered by MetaFin Consulting, LLC (through its financial wing
                            Universal Financials) which shall prepare and
                            File Federal/State/Local Tax Returns as required and requested by you. <b>Please review these Terms of
                                Usage carefully. By using the services of MetaFin Consulting, LLC, you agree to these Terms of
                                Usage.</b> It is your responsibility to review these Terms of Usage periodically, and if at any time
                            you find these Terms of Usage unacceptable or if you do not agree to these terms of usage, please do not
                            proceed with using our services and write to us at <a target="_blank" href="mailto:contact@universalfinancials.com"><b>contact@universalfinancials.com</b></a>
                            with your concerns. MetaFin Consulting, LLC reserves the right to revise terms of usage at any time
                            without any prior notice to you.</p>
                        <ul class="usindividual" style="margin-top:5px;">
                            <li>MetaFin Consulting, LLC shall collect necessary information required for preparation of Tax Returns
                                from you through email or phone. MetaFin Consulting, LLC shall prepare the Tax Returns based on the
                                information provided by you and does not hold any responsibility towards verification and accuracy
                                of such information.
                            </li>
                            <li>Apart from the information provided by you, MetaFin Consulting, LLC may use certain additional
                                information in preparation of your Tax Returns as part of planning scope by interpreting certain Tax
                                Laws provided by the Federal and the State Revenue Departments from time to time.
                            </li>
                            <li>After the Tax Return is prepared, MetaFin Consulting, LLC shall provide you with the status on your
                                Tax Liability (whether Refund or Due). If incase any planning scope is applied on your Tax Return,
                                MetaFin Consulting, LLC shall provide the Tax Liability position before and after planning based on
                                which you shall have an option to choose while making the payment. Kindly note that the usage of
                                planning scope is optional and you may ask MetaFin Consulting, LLC to exclude the same from your Tax
                                Returns anytime before making the payment.
                            </li>
                            <li>MetaFin Consulting, LLC shall charge reasonable amount of fees towards services rendered that
                                include – Planning, Preparation and Filing of your Tax Returns. Kindly note that there is a separate fee applicable for 'Tax Returns without
                                planning benefit' and 'Tax Returns with planning benefit'. Hence, it is very important to choose the
                                correct option before making the payment. After the payment is made, it is upon the sole discretion
                                of MetaFin Consulting, LLC to charge additional fee or refund excess fee, as the case maybe.
                            </li>
                            <li>
                                Pricing: Our pricing is very competitive when compared to others in this industry. We charge US Individual Tax Return preparation fee of $24.99 for Federal Return and $29.99 for each State/Local Return. Apart from preparation fees, additional charges may apply depending on the complexity of Tax Return(s) and/or the benefits claimed/Services required by the client.
                            </li>
                            <li>
                                Refund Policy: If for any reason, you wish to discontinue proceeding with our services, after making
                                the payment, we will refund you the total fees paid by you provided, if the tax return is not
                                released for your review. But if you wish not to proceed with our services after the tax document is
                                released for your review, we shall hold the minimum of the Preparation Fees or $100, whichever is
                                higher, and refund the rest of the amount.
                            </li>
                            <li>In cases of Negligent Preparation, MetaFin Consulting, LLC may take responsibility of such negligent
                                preparation, if proved and assures to pay the penalty amount if any levied by the Tax authorities
                                resulting out of such negligence. (Negligence here means any mistake clearly seen on the face of it
                                or an error which can be identified by a rational person with naked eye). In all cases including
                                negligence, the amount payable by MetaFin Consulting, LLC or its representatives will be limited to
                                the amount of fee paid by you towards utilization of our service or the penalty amount, whichever is
                                less.
                            </li>
                        </ul>
                        <p class="mt25 mb0"><b>Privacy Policy:</b></p>

                        <p>MetaFin Consulting, LLC completely understand the importance of customer information and respects the
                            privacy of its customers. It takes all possible measures to safeguard all personal information
                            (sometimes referred to as Personally Identifiable Information' or PII) collected by it. </p>

                        <p class="mt25 mb0"><b>Collection &amp; Use of PII:</b></p>

                        <p>In process of offering our services such as Taxation Services, MetaFin Consulting, LLC shall collect
                            several types of information including Sensitive information like Date of Birth, Social Security Number,
                            Bank account information, Income and expense details, etc. Apart from this, we may also gather
                            information like User IP address, browser type, etc for security and such other reasons. By registering
                            and/or submitting personal information on our web sites, you are also agreeing to the use of this
                            information in accordance with this Privacy Statement. Your personal information is not used for other
                            purposes, unless we obtain your permission, or unless otherwise required or permitted by law or
                            professional standards.</p>

                        <p class="mt25 mb0"><b>Sharing and Transfer of PII:</b></p>

                        <p>We do not share personal information with unaffiliated third parties, except as necessary for our
                            legitimate professional and business needs, to carry out your requests, and/or as required or permitted
                            by law or professional standards. In some instances, MetaFin Consulting, LLC may also share PII about
                            you with various outside companies or service providers or vendors working on our behalf to help fulfill
                            your requests</p>

                        <p>In this regard, kindly note that MetaFin Consulting, LLC have Knowledge centers outside of United States
                            of America (mainly India) and there is possibility that the information provided by you may be used by
                            the Employees/Affiliates/Partners working from such offices in the process of preparing your Tax
                            Returns. MetaFin Consulting, LLC takes all necessary precautions to maintain Security Standards not only
                            in United States of America but also in such other countries. MetaFin Consulting, LLC may also store PII
                            in a jurisdiction other than where you are based. By providing PII on MetaFin Consulting, LLC web site
                            or through any other modes, users are consenting to this transfer and/or storage of their data across
                            borders.</p>

                        <p class="mt25 mb0"><b>Children:</b></p>

                        <p>MetaFin Consulting, LLC understands the importance of protecting children's information, especially in an
                            online environment. This privacy statement is not intended for use by children and we do not knowingly
                            collect or maintain information about children, except as part of an engagement to provide professional
                            services.</p>

                        <p class="mt25 mb0"><b>Date Security &amp; Integrity:</b></p>

                        <p>MetaFin Consulting, LLC has reasonable technical and organizational Security measures in place to protect
                            personal information against unauthorized disclosure or access and against any other unlawful forms of
                            processing. However, these measures are not absolute. In addition, the transmission of data over the
                            Internet by its nature entails the use of systems under the control of third parties, and as a result
                            MetaFin Consulting, LLC cannot ensure the security of such systems.</p>

                        <p class="mt25"><b>Changes to this policy:</b></p>

                        <p>MetaFin Consulting, LLC may modify this Privacy Statement from time to time to reflect our current
                            privacy policies and practices. We encourage you to periodically review this Privacy Statement to be
                            informed about how MetaFin Consulting, LLC is protecting your information.</p>

                        <p class="mt25 mb0"><b>Indemnification:</b></p>

                        <p>It is your (Client) responsibility and obligation to provide accurate information to the representatives
                            of MetaFin Consulting, LLC in the course of preparation of your Tax Returns and incase of any violation,
                            it is you who are solely responsible for all claims, damages, losses or expenses arising out of and in
                            any way connected to provision of such wrong information and you shall indemnify or defend MetaFin
                            Consulting, LLC from such claims, damages, losses or any related expenses. You shall also indemnify
                            MetaFin Consulting, LLC from all losses, claims, damages, costs, actions or other consequences of any
                            sort arising out of use or misuse, whether intentional or unintentional, of any material or advice
                            provided to you by MetaFin Consulting, LLC. In this regard, it is your responsibility to also refer to
                            'Disclaimer' clause as given in other section of this website. </p>

                        <p class="mt25 mb0"><b>Force Majeure:</b></p>

                        <p>MetaFin Consulting, LLC is not responsible for any delay or failure in performance of the website in
                            whole or in part for any reason including, without limitation: fires, floods, storms, earthquakes, civil
                            disturbances, disruption of telecommunications, hacker attack, governmental action, computer viruses,
                            data corruption, incompatible or defective equipment, software or services or otherwise.</p>

                        <p>BY USING THE SERVICES OF METAFIN CONSULTING, LLC OR ACCESSING THE WWW.UNIVERSALFINANCIALS.COM OR
                            WWW.METAFINCONSULTING.COM WEBSITES,
                            YOU ACKNOWLEDGE THAT YOU HAVE READ THE ABOVE MENTIONED TERMS AND AGREE TO ABIDE BY THEM.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
