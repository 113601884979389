<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Disclaimer <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <!--div class="section-title modalTitle">
                            <h2>Indian Incorporation<span> Services</span></h2>
                        </div-->
                        <p>By using this Site, you are agreeing to be bound by the following terms and conditions along with 'Terms
                            of Usage' as given in other section of this website.</p>

                        <p>MetaFin Consulting, LLC may furnish certain information on this site from time to time with an intention
                            of providing certain basic knowledge on various financial matters to its users. While every effort shall
                            be made to offer current and accurate information, errors might occur. Furthermore, this Site may
                            contain references to certain Laws and Regulations which tend to change over time. The same need to be
                            interpreted only in light of particular circumstances.</p>

                        <p>METAFIN CONSULTING, LLC DISCLAIMS ALL LIABILITY AND RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE
                            CONTENT CONTAINED ON THIS SITE.</p>

                        <p>All content on the Site and all services provided through it are provided "as is", with no guarantees of
                            completeness, accuracy or timeliness, and without representations, warranties or other contractual terms
                            of any kind, express or implied. MetaFin Consulting, LLC does not represent or warrant that this Site,
                            the various services provided through this Site, and / or any information, software or other material downloaded from this Site, will be accurate, current, uninterrupted,
                            error-free, omission-free or free of viruses or other harmful components.</p>

                        <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, METAFIN CONSULTING, LLC DISCLAIMS ALL
                            LIABILITY TO YOU AND EVERYONE ELSE IN RESPECT OF THE CONTENT ON THIS SITE AND ALL SERVICES PROVIDED
                            THROUGH IT, WHETHER UNDER ANY THEORY OF TORT, CONTRACT, WARRANTY, STRICT LIABILITY OR NEGLIGENCE OR
                            OTHERWISE, AND WHETHER IN RESPECT OF DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE OR SIMILAR
                            DAMAGES, EVEN IF UNIVERSAL FINANCIALS OR ITS SUBSIDIARIES WERE ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE
                            POSSIBILITY OF SUCH DAMAGES.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
