import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-about-full-content',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    @Input() rowData;
    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    close() {
        this.activeModal.close();
    }

}
