<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>Indian Incorporation<span> Services</span></h2>
                        </div>
                        <div class="border mt25 mb15"></div>
                        <p>For an entrepreneur, it is important not just to make money or provide service but it is equally essential to follow all the Statutory and Regulatory compliances prescribed by the concerned governing bodies. Incorporating or Registering an Enterprise is one among them and is required to avoid unnecessary legal issues in future.</p>
                        <p>Universal Financials has a team of experienced professionals who handle all the Incorporation procedures. Incorporation includes setting up a Company in India as per the Indian Companies Act, 1956 or a Partnership Firm as per the Partnership Act, 1932. Our customized services shall definitely be of great help in making you understand the applicable Laws.</p>
                        <p>The Companies Act of 1956 sets down rules for the establishment of both "Public" and "Private" companies. Public company can be formed with a minimum of 7 members while a Private company can be formed with members between 2 to 50. The Private Company prohibits invitation to public for capital issues. Many provisions of the Companies Act are not applicable on Private Limited companies.</p>
                        <p>Private limited companies are best suited to foreign entities which want to setup their operations in India with their own money or with limited partners, and are not planning for a public Issue.</p>
                        <p><b>Procedure of Company Formation:-</b></p>
                        <ul class="usindividual">
                            <li>Allotment of Director Identification Number (DIN)</li>
                            <li>Acquiring Digital Signature certificate (DSC)</li>
                            <li>Name Approval</li>
                            <li>Preparation of Memorandum (MOA) and Articles of Association (AOA)</li>
                            <li>Filing all the required documents with the Registrar of Companies.</li>
                            <li>Certificate of Incorporation.</li>
                        </ul>
                        <p><b>Universal Financials can help you in setting up of a Company and/or a Partnership Firm including setting up of Branch offices in major cities of India at low handling charges. For more information on our Incorporation Services, please mail your requirement to <a href="contact@universalfinancials.com">contact@universalfinancials.com</a></b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
