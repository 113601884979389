<section class="welcome-area custModalBoxDesing">
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <!--div class="pageModalHeading">Universal Financials <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div-->
    <a (click)="close()" class="closeBtn"></a>
    <div class="container">
        <div class="row bb1">
            <div class="col-sm-12 col-xs-12">
                <div class="modal-title-Label">
                    We at Universal Financials request all our clients, well-wishers and their families to take all the protective
                    and precautionary measures against the spread of pandemic COVID-19 (Corona). Please visit
                    <a class="whoBtn" href="https://www.who.int/" target="_blank">WHO</a> for more information……
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12  col-xs-12">
                <div class="modal-title-Label pt10 modal-title-Label-cust-message">
                    <p>Due to outbreak of Covid-19 pandemic and Lockdowns announced by Governments, we are working with very limited resources.
                        This could result in delay in our Services for next few weeks. We apologize for the inconvenience. Thank you for your understanding.</p>
                    <p>For your information, IRS had extended the TY2023 Individual Income Tax Return filing Due Date to July 15, 2023.</p>

                </div></div>
        </div>
        <a href="https://www.universalfinancials.com/mytax/#/login" style="position: absolute;width: 257px;height: 30px;bottom: 0;display: block;left: 30%;"></a>
    </div>
</section>
