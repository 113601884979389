import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-full-content',
  templateUrl: './about-full-content.component.html',
  styleUrls: ['./about-full-content.component.scss']
})
export class AboutFullContentComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
    close() {
        this.activeModal.close();
    }

}
