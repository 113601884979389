<section id="about" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                About Us
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">About Us <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <!--div class="fa-pull-right"><img src="./assets/img/aboutimage_v1.png"></div-->
                <p>Universal Financials, an initiative from MetaFin Consulting, LLC, registered in the State of New Jersey,
                    USA is a firm established by a group of qualified professionals consisting of CPAs and EAs with the sole
                    aim of providing finest Quality Service to its esteemed clients. It is the brain child of few
                    individuals who have come together with a vision to mark a difference in the financial world. It caters to the financial services
                    requirement within the United States of America and India.</p>

                <p>There are many companies in the market catering to the financial needs of customers. They do what the
                    customer asks for and on receiving such Service, even the customers get satisfied. But here we need to
                    remember one thing - Any financial transaction that we do is nothing but spending our hard earned money
                    and hence it's very important for everyone to think twice on whether it is worth to enter into such
                    transaction.</p>

                <p>As we all know, a particular thing can be done in many ways and so do financial transaction. Let's take
                    an instance of Filing a Tax Return; a customer approaches a Service Provider and asks to file a Tax
                    Return and accordingly the Service Provider prepares and files the Return.</p>

                <p> On completion of filing, the customer gets satisfied. But, here we need to understand as to how the Tax
                    Return is prepared. One can just add all incomes, claim generally allowed deductions and can arrive at
                    Tax Liability or Refund. If it is a Refund, the customer will get money and if it's Tax Liability, the
                    customer needs to shell out some money. Though the customer's requirement is taken care, it could have
                    been done more effectively and efficiently by applying certain Tax Planning tools which shall result in
                    more Refund amount or less Tax Liability.</p>

                <p>Here comes the significance of Universal Financials. We not only fulfill the financial requirement of our
                    clients but also do it effectively and efficiently. We understand the importance of our clients and
                    always try to deliver our best Services. We treat every customer as our first customer and serve with
                    special care and attention. Our slogan - Avail our Service and Feel the difference.</p>

                <p>The strength of Universal Financials lies in its team of Qualified and Trained professionals who treasure
                    the value of diligence and knowledge in addressing the client's needs. We offer our clients the benefit
                    of Knowledge, based on our experience and extensive research on various Financial and Statutory
                    matters.</p>
            </div>
        </div>
    </div>
</section>
