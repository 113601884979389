<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>US Tax <span>Representation</span></h2>
                        </div>
                        <div class="border"></div>
                        <h6 class="usindividualservices">Tax Representation Services (Federal &amp; States) :</h6>
                        <p>One of the biggest challenges faced by the Taxpayers is handling of Tax queries raised by the Federal and/or the State Tax Departments from time to time on the previously filed Returns. Such Tax queries may be of a general nature or of a specific nature. Whatever is the nature of such query (whether general query resulting out of routine audit or a specific query resulting out of seeking clarification on any particular item reported on the Tax Return), it becomes cumbersome for a Taxpayer to spend time and effort on such queries and reply/represent them within the stipulated time.</p>
                        <p>Keeping in view of this, we have setup a separate division to handle such Representation cases. Our uniqueness lies in responding to these queries in the stipulated time and in an appropriate manner thereby trying to reduce the penalties and interest, if any charged.</p>
                        <p>Our Tax Representation division handles all type of Representation cases including ITIN Rejection cases, Audit cases, etc based on the nature of query received from the Federal and State Revenue Departments.</p>
                        <p><b>Please Note:</b> By Filing your Tax Return with Universal Financials you may avoid such unnecessary tensions with its multi-level review system ensuring accuracy of Tax Returns</p>
                        <p><b>We are here to serve you! Come and experience it! File your taxes with minimum charges!! Make the most of now.</b></p>
                        <p><b>Our services at Business/corporate level:</b> Universal Financials also offer various value added services for your businesses whether it is Small, Medium or Large enterprise. Please refer to our 'US Corporate Services' section for more information.</p>
                        <p><a href="/mytax/#/register" class="btn btn-primary">Get Started</a></p>

                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
