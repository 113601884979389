import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-full-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss']
})
export class ArticleContentComponent implements OnInit {
    @Input() rowData;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
    close() {
        this.activeModal.close();
    }

}
