import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import {MastersService} from '../../../shared/services/masters.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FooterFullReferralPolicyComponent} from '../../footer-full-Referral-Policy/footer-full-Referral-Policy.component';

@Component({
    selector: 'app-refer-a-friend',
    templateUrl: './ReferAFriend.component.html',
    styleUrls: ['./ReferAFriend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
    dataForm: FormGroup;
    submitted = false;
    constructor(private fb: FormBuilder, private mastersService: MastersService, private modalService: NgbModal) {

    }

    ngOnInit() {
        this.buildForm();
    }
    openFullReferralPolicy() {
        const modalRef = this.modalService.open(FooterFullReferralPolicyComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }
    buildForm(): void {
        this.dataForm = this.fb.group({
            id: null,
            yourName: [null, Validators.required],
            yourEmail: [null, [Validators.required, Validators.email]],
            yourPhone: [null, Validators.required],
            friendName: [null, Validators.required],
            friendEmail: [null, [Validators.required, Validators.email]],
            friendPhone: [null, Validators.required]
        });
    }

    onDataSubmit() {
        this.submitted = true;
        console.log(this.dataForm.value);
        if (this.dataForm.invalid) {
            return;
        }
        this.mastersService.referFriend(this.dataForm.value)
            .subscribe((data) => {
                console.log(data);
                this.submitted = false;
                this.openAlert();
                this.buildForm();
            }, (error) => {
                console.log(error);
                alert(error.message);
            });
    }

    get f() {
        return this.dataForm.controls;
    }

    openAlert() {
        const modalRef = this.modalService.open(ConfirmationComponent, {
            size: 'md'
        });
        const message = {
            title: 'Greetings from Universal Financials !!',
            message: 'Thank you for keeping confidence in us and referring your friends/colleagues to Universal Financials. We assure you of offering our best possible services to your referred ones.'
        };
        modalRef.componentInstance.rowData = message;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }
}
