import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MastersService} from '../../../shared/services/masters.service';
import {AboutFullContentComponent} from '../../about-full-content/about-full-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import {apiConfig} from '../../../api-url.config';

@Component({
    selector: 'app-wantus-call',
    templateUrl: './wantus-call.component.html',
    styleUrls: ['./wantus-call.component.scss']
})
export class WantusCallComponent implements OnInit {
    dataForm: FormGroup;
    submitted = false;
    scheduleTimeList: any = apiConfig.scheduleTimeList;
    services: any = apiConfig.services;
    navigation: 'select';
    dselectDate: any;
    todayDate: any = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };
    constructor(private fb: FormBuilder, private mastersService: MastersService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm(): void {
        this.dataForm = this.fb.group({
            id: null,
            name: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            contact: [null, Validators.required],
            selectDate: ['', Validators.required],
            selectTime: [null, Validators.required],
            services: [null, Validators.required],
            comments: ['', [Validators.required]]
        });
    }

    onDataSubmit() {
        this.submitted = true;
        console.log(this.dataForm.value);
        if (this.dataForm.invalid) {
            return;
        }
        this.mastersService.wantusCall(this.preparePayload())
            .subscribe((data) => {
                console.log(data);
                this.submitted = false;
                this.openAlert();
                this.buildForm();
            }, (error) => {
                console.log(error);
                alert(error.message);
            });
    }

    get f() {
        return this.dataForm.controls;
    }

    openAlert() {
        const modalRef = this.modalService.open(ConfirmationComponent, {
            size: 'md'
        });
        const message = {
            title: 'Greetings from Universal Financials !!',
            message: 'Thank you for your interest in using our services. One of our associates shall get in touch with you at the scheduled time.'
        };
        modalRef.componentInstance.rowData = message;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    private preparePayload() {
        const payload = this.dataForm.value;
        payload.selectDate = this.mastersService.getDateFormat(payload.selectDate);
        return payload;
    }
}
