import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {HomeOneComponent} from './components/home-one/home-one.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home', component: HomeOneComponent},

    {path: '**', component: HomeOneComponent} // This line will remain down from the whole pages component list
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    useHash: false
};

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
