import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-services-full-content',
    templateUrl: './services-full-ITIN-and-FBAR-Filing.component.html',
    styleUrls: ['./services-full-ITIN-and-FBAR-Filing.component.scss']
})
export class ServicesFullITINAndFBARFilingComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }
    close() {
        this.activeModal.close();
    }

}
