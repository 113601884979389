<section class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                About Us
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Thank you <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <h4>{{rowData.title}}</h4>
                <p [innerHTML]="rowData.message"></p>
            </div>
        </div>
    </div>
</section>
