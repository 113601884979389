import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {apiConfig} from '../../api-url.config';

@Injectable()
export class MastersService {

    API_UNIVERSAL: string;

    constructor(private httpClient: HttpClient) {
        this.API_UNIVERSAL = environment.API_UNIVERSAL;
    }

    contactUs(payload: any): Observable<any> {
        return this.httpClient.post(`${this.API_UNIVERSAL}${apiConfig.contactUs}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((error: any) => {
                    throw(error.error);
                })
            );
    }

    referFriend(payload: any): Observable<any> {
        return this.httpClient.post(`${this.API_UNIVERSAL}${apiConfig.referFriend}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((error: any) => {
                    throw(error.error);
                })
            );
    }

    wantusCall(payload: any): Observable<any> {
        return this.httpClient.post(`${this.API_UNIVERSAL}${apiConfig.wantusCall}`, payload)
            .pipe(
                map((res: any) => res),
                catchError((error: any) => {
                    throw(error.error);
                })
            );
    }

    blog(): Observable<any> {
        return this.httpClient.get(`${this.API_UNIVERSAL}${apiConfig.blog}`)
            .pipe(
                map((res: any) => res)
            );
    }

    getDateFormat(dateObj: any): string {
        if (dateObj && dateObj.year) {
            return dateObj && dateObj.year ? dateObj.year + '-'
                + dateObj.month.toString().padStart(2, '0') + '-'
                + dateObj.day.toString().padStart(2, '0') : dateObj;
        } else if (dateObj) {
            return dateObj;
        } else {
            return dateObj;
        }
    }
}
