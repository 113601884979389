<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>US Accounting<span> & Book Keeping</span></h2>
                        </div>
                        <div class="border"></div>
                        <h6 class="mt25 mb15">US Accounting Services</h6>
                        <p>The profitability position of a business can be known only when the Books of Accounts are properly maintained. It is also a statutory and compliance requirement to maintain accounts as per the applicable Rules and Laws. Universal Financials can be of help to you in proper maintenance of books of accounts. We record all the transactions you do by keeping the books of accounts on a periodical basis and provide you with the MIS Reports that are required for taking decisions on the expansion of business. We have the necessary expertise to serve all types of entities such as LLC, LLP, C-Corp, S-Corp, etc and all size of entities such as small, medium and large entities.</p>
                        <p><b>Below are some of the services we provide:</b></p>
                        <ul class="normalul">
                            <li>Preparing the chart of Accounts</li>
                            <li>Preparing the Monthly Financial Statements</li>
                            <li>Preparing Cash Flow Statements</li>
                            <li>Managing Accounts Payable and Accounts Receivable</li>
                            <li>Preparing the Invoices, Revenues and Sales Reports</li>
                            <li>Preparation of Annual Financial Statements</li>
                            <li>Providing the customized Reports on demand</li>
                        </ul>
                        <p>Our Trained Accounting personnel shall assist you in providing the detailed list of Accounting Services on request. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
