<app-full-page-navbar [activeLink]="'SERVICES'"></app-full-page-navbar>
<app-topfixednav></app-topfixednav>
<section id="about" class="welcome-area pt-100 pb-70 pt-adj">
    <div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>Indian Financial Services</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title">
                            <h2>Indian Financial <span>Services</span></h2>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-xs-12 pd-0">
                                <div class="abtImg"><img src="images/services1.png"></div>
                                <h2 id="usindividualservices">Indian Individual & Corporate Taxation Including Service
                                    Tax & Vat:</h2>
                                <p>Our Tax Experts can assist you in timely preparation and filing of Individual &
                                    Corporate Tax Returns (including Firms, Trusts, etc) and can help you in minimizing
                                    the Tax Liability efficiently without compromising on legal matters. Our Services
                                    are tailor made to provide Tax Consultations and advices based on the nature and
                                    size of the business.</p>
                                <p><b>Our Income Tax Services include:-</b></p>
                                <ul class="usindividual" style="margin-top:7px;">
                                    <li>Tax Management and Advisory services to manage tax liability.</li>
                                    <li>Calculation of Advance Taxes.</li>
                                    <li>Filing of Individual Income Tax Returns.</li>
                                    <li>Filing of Corporate Tax Returns, Wealth Tax, Tax Deducted at Source (TDS),
                                        etc.
                                    </li>
                                    <li>Applying and obtaining Permanent Account Numbers (PANs) and Tax deduction
                                        Account Numbers (TANs).
                                    </li>
                                    <li>Preparation &amp; Filing of Returns under VAT and Service Tax Act.</li>
                                </ul>

                                <div class="bottombuttions">
                                    <div><a href="mytax/login.php"><img onclick="" src="images/getstarted.png "></a>&nbsp;&nbsp;<a
                                        href="services.html"><img onclick="" src="images/goback.png "></a></div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
