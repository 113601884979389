import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MastersService} from '../../../shared/services/masters.service';
import {AboutFullContentComponent} from '../../about-full-content/about-full-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    dataForm: FormGroup;
    submitted = false;

    constructor(private fb: FormBuilder, private mastersService: MastersService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm(): void {
        this.dataForm = this.fb.group({
            id: null,
            name: [null, Validators.required],
            contact: [null, Validators.required],
            usrTimezone: ['', Validators.required],
            email: [null, [Validators.required, Validators.email]],
            country: ['', [Validators.required]],
            message: ['I wish to discuss on US Income Tax Return filing. Please call me ... Thanks.']
        });
    }

    onDataSubmit() {
        this.submitted = true;
        console.log(this.dataForm.value);
        if (this.dataForm.invalid) {
            return;
        }
        this.mastersService.contactUs(this.dataForm.value)
            .subscribe((data) => {
                console.log(data);
                this.submitted = false;
                this.openAlert();
                this.buildForm();
            }, (error) => {
                console.log(error);
                alert(error.message);
            });
    }

    get f() {
        return this.dataForm.controls;
    }

    openAlert() {
        const modalRef = this.modalService.open(ConfirmationComponent, {
            size: 'md'
        });
        const message = {
            title: 'Greetings from Universal Financials !!',
            message: 'We thank you for giving us the opportunity to serve you. One of our representatives will get in touch with you shortly. Hope you will enjoy our enthused and prompt service which makes us to stand alone in this arena of business. Looking forward for a long-lasting business relationship with you. For further help and information, request you to forward us an email to <a href="mailt0:contact@universalfinancials.com"><b>contact@universalfinancials.com</b></a>.'
        };
        modalRef.componentInstance.rowData = message;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }
}
