import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-full-page-navbar',
    templateUrl: './full-page-navbar.component.html',
    styleUrls: ['./full-page-navbar.component.scss']
})
export class FullPageNavbarComponent implements OnInit {

    @Input() activeLink: string;

    links: any;

    constructor() {
    }

    ngOnInit() {
        this.links = [
            {
                name: 'HOME',
                url: '/../home',
                active: '',
                fragment: 'home'
            },
            {
                name: 'ABOUT US',
                url: '/../home',
                active: '',
                fragment: 'about'
            },
            {
                name: 'SERVICES',
                url: '/../home',
                active: '',
                fragment: 'services'
            },
            {
                name: 'REFER A FRIEND',
                url: '/../home',
                active: '',
                fragment: 'referafriend'
            },
            {
                name: 'RESOURCES',
                url: '/../home',
                active: '',
                fragment: 'resources'
            },
            {
                name: 'CAREERS',
                url: '/../home',
                active: '',
                fragment: 'careers'
            },
            {
                name: 'Contact',
                url: '/../home',
                active: '',
                fragment: 'contact'
            }
        ];


        this.links = this.links.map((link: any) => {
            if (link.name === this.activeLink) {
                link.url = '';
                link.active = 'active';
            }
            return link;
        });
    }

}
