<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Referral Policy<span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0 terms">
                        <p>MetaFin Consulting, LLC and its financial wing Universal Financials is one of the fastest growing firms that have gained reputation of offering timely and accurate services at all times. If our dedicated and highly committed team is one of the reasons to make this possible, the other and most important reason is our valuable Customers. Without the kind support and cooperation of our clients, we would not have been at a place where we are today. We are always thankful to our customers who have been supporting us all along.</p>
                        <p>In return of this kind gesture of our clients like you and in order to take our services to many more customers, we have newly introduced a 'Referral Policy' which not only can help us to reach new customers but can also benefit you monetarily or otherwise. </p>
                        <p><strong>What is covered under this program? </strong></p>
                        <p>'Referral Policy' (or Refer a Friend program) gives you an opportunity to earn some extra income by telling about our US Income Tax Return Filing Services to your Friends, Colleagues and Relatives.</p>
                        <p><strong>How are you  Benefited?</strong></p>
                        <p>By Referring your Friends, Colleagues and Relatives to file  their US Income Tax Returns with Universal Financials, you shall qualify  for - </p>
                        <p><strong><u>10% Referral Bonus</u></strong> - Become eligible to <strong>receive 10% of Fee paid by your Referral  as 'Referral Bonus'</strong> - On Successful filing of US Income Tax Return for Tax  Year 2023 by your referral with Universal Financials, you shall become  eligible to receive 10% of the amount your referral pays to us towards Tax  Return Filing charges.</p>

                        <p><strong><u>'Top Three'  Referral Program</u></strong> - Gain an opportunity to be part of the lucky '<em>Top Three</em>'- If you are one among the top  Referrals, you have a chance to win <strong>FREE  TICKET TO INDIA, APPLE IPHONE </strong>and <strong>SONY DIGITAL CAMERA. </strong>Apart of these, there are many more prizes to  be won.</p>
                        <p><strong><u>Corporate/Group  Discount</u></strong> - Universal Financials also <strong>offer Corporate/Group Discount</strong> for customers who come in groups to  file their Individual Income Tax Return for the Tax  Year 2023<strong>. </strong>The discount percentage shall be as  under <strong>-</strong></p>

                        <div class="row">
                            <div class="col-md-5 col-md-offset-2">
                                <table class="table table-bordered">
                                    <tbody><tr>
                                        <td width="312" valign="top"><p><strong>Group of 3 - 5 Taxpayers</strong></p></td>
                                        <td width="138" valign="top"><p align="center"><strong>10%</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td width="312" valign="top"><p><strong>Group of 6 - 15 Taxpayers</strong></p></td>
                                        <td width="138" valign="top"><p align="center"><strong>15%</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td width="312" valign="top"><p><strong>Group of 16 - 25 Taxpayers</strong></p></td>
                                        <td width="138" valign="top"><p align="center"><strong>20%</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td width="312" valign="top"><p><strong>Group with more than 25    Taxpayers</strong></p></td>
                                        <td width="138" valign="top"><p align="center"><strong>25%</strong></p></td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>


                        <p><strong>Points to Note:</strong></p>
                        <ul class="usindividual">
                            <li>The said Referral Policy is  applicable only towards filing of United States Individual Income Tax Return  for the Tax  Year 2023.</li>
                            <li>The person whom you refer should not be an existing customer of Universal Financials. </li>
                            <li>All payments under the  Referral program including dispatch of gift items under the '<strong>Top Three</strong>' Referral plan shall be made  after May 31, 2024.</li>
                            <li>The minimum referrals per  individual must be more than 'five' to participate in 'Top Three' Referral  program.</li>
                            <li>Individuals who have claimed  discount or are covered under any 'Corporate/Group Discount' program will not  be eligible to participate in '<em>Top Three</em>'  Referral program.</li>
                            <li>Images given in the website are only for an indication and doesnot resemble the actual prizes. The color and features of actual prizes may vary.</li>
                            <li>All precautions shall be taken  to conduct the Referral program fair and transparent but in case of any  dispute, the decision of Universal Financials shall be final and cannot be  challenged.</li>

                        </ul>
                        <!--p class="reflink"><a href="https://www.universalfinancials.com/referafriend.html" title="Click Here to Refer your Friends and Relatives" style="text-decoration:underline;color:#006697;">Click Here to Refer your Friends and Relatives</a>  </p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
