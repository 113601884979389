
<section class="strategy-area about-area ptb-100 bg-F7F5F4" id="IndianFServices">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <!--h4>Highly Creative Solutions</h4-->
                        <h2 class="fs40">Indian  <span>Financial Services</span></h2>
                        <!--h2>INDIAN  <span>FINANCIAL SERVICES</span></h2-->
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p-->
                    </div>
                    <ul class="features-list ulCustHover">
                        <li class="feListSub" (click)="openINDIndividualCorporateTaxes()"><i class="fa fa-check"></i>Indian Individual & Corporate Taxes</li>
                        <li class="feListSub" (click)="openINDIncorporations()"><i class="fa fa-check"></i>Indian Incorporations</li>
                        <li class="feListSub" (click)="openINDAccountingServices()"><i class="fa fa-check"></i>Indian Accounting Services</li>
                        <li class="feListSub" (click)="openINDPayrollServices()"><i class="fa fa-check"></i>Indian Payroll Services</li>
                        <li class="feListSub" (click)="openINDOtherServices()"><i class="fa fa-check"></i>Other Services</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="IndianFServices">
                    <img src="./assets/img/Indian-Financial-IMG-min.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="./assets/img/shape1.png" alt="image"></div>
</section>
