<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Connect</span></h2>
            <p>To know more about our Services and for any other inquiry, please fill the below form so that one of our
                representatives will get in touch with you.</p>
            <p>We, at Universal Financials are sensitive to customer concerns thereby ensure absolute confidentiality
                and privacy of any information that is given to us. We will not disclose their identity without
                expressed or written consent from our customers.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="dataForm" (submit)="onDataSubmit()" class="needs-validation">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" formControlName="name" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.invalid, 'is-valid': submitted && !f.name.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.name.invalid || f.name.errors?.required)">Please Enter
                                        Name</small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Contact Number</label>
                                    <input type="text" formControlName="contact" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.contact.invalid, 'is-valid': submitted && !f.contact.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.contact.invalid || f.contact.errors?.required)">Please
                                        Enter Contact Number</small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Time Zone</label>
                                    <select id="contactform-usrTimezone" class="form-control"
                                            formControlName="usrTimezone" aria-required="true"
                                            [ngClass]="{ 'is-invalid': submitted && f.usrTimezone.invalid, 'is-valid': submitted && !f.usrTimezone.invalid }">
                                        <option value="">Please Select</option>
                                        <option value="CST">Central Standard Time (CST)</option>
                                        <option value="EST">Eastern Standard Time (EST)</option>
                                        <option value="MST">Mountain Standard Time (MST)</option>
                                        <option value="PST">Pacific Standard Time (PST)</option>
                                        <option value="IST">Indian Standard Time (IST)</option>
                                    </select>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.usrTimezone.invalid || f.usrTimezone.errors?.required)">Please
                                        Select Time Zone</small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email ID</label>
                                    <input type="email" formControlName="email" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.invalid, 'is-valid': submitted && !f.email.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.email.invalid || f.email.errors?.required)">Please
                                        Enter Email</small>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Country</label>
                                    <select id="contactform-country" class="form-control" formControlName="country"
                                            aria-required="true"
                                            [ngClass]="{ 'is-invalid': submitted && f.country.invalid, 'is-valid': submitted && !f.country.invalid }">
                                        <option value="">Please Select</option>
                                        <option value="CAN">Canada</option>
                                        <option value="IND">India</option>
                                        <option value="GBR">United Kingdom</option>
                                        <option value="USA" selected="">United States of America</option>
                                    </select>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.country.invalid || f.country.errors?.required)">Please
                                        Enter Country</small>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Brief Description of your Requirement</label>
                                    <textarea class="form-control" formControlName="message" cols="30" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Corporate Office:</span>208 West State
                                    Street, Trenton, NJ 08608
                                </li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1-626-626-7935"><span>Call:</span>1-626-626-7935</a></li>
                                <li><i class="fas fa-building"></i> MetaFin Consulting India Private Limited</li>
                                <li><i class="fas fa-map-marker-alt"></i> <span>India Offices:</span>Shri Shanta Arcade,
                                    L.B.Nagar, Hyderabad.
                                </li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:8501003000"><span>Call:</span>+91-8501003000</a>
                                </li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:contact@universalfinancials.com">contact@universalfinancials.com</a><br/><a
                                    href="mailto:clientservices@universalfinancials.com">clientservices@universalfinancials.com</a>
                                </li>
                                <!--li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
