<section id="about" class="welcome-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-2 p0 marquee_leftBlock pr0" style="width: 95%;">
                <div class="marquee_left">Tax Updates <i class="fa fa-caret-right" aria-hidden="true"></i></div>
            </div>
            <div class="col-md-10 p0">
                <div class="marquee">
			        <span>
                        <a (click)="showArticle(row)" *ngFor="let row of articles"><i class="fa fa-angle-double-right"></i> {{row.title}}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-title">
            <h4></h4>
            <h2><span class="welcomeHeading">Welcome to </span> <b> UNIVERSAL</b> <span> <b> FINANCIALS</b></span></h2>
            <p>The strength of Universal Financials lies in its team
                of Qualified and Trained professionals who treasure the value of
                diligence and knowledge in addressing the client's needs. We offer our
                clients the benefit of Knowledge, based on our experience and extensive
                research on various Financial and Statutory matters.</p>
            <p class="text-center"><a (click)="openAbout()" class="btn btn-primary">Read More...</a></p>
        </div>

        <!--div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Creative Design</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Friendly Codes</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Fast Support</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <a href="#about" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div-->
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>

