<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span>
    </div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>ITIN <span>Services</span></h2>
                        </div>
                        <div class="border"></div>
                        <!--h6 class="usindividualservices">Tax Planning, Preparation &amp; Filing Services :</h6-->
                        <p>Universal Financials is a one stop go for all your ITIN requirements!!</p>
                        <p>Have a Spouse or qualified dependent who are staying with you but does not hold a valid
                            Social Security Number (SSN)? No problem!! <b>Universal Financials</b> can assist you in
                            obtaining Individual Taxpayer Identification Number (ITIN) for your Spouse/Dependent.</p>
                        <p><b>Individual Taxpayer Identification Number (ITIN)</b> is a Tax identification Number which
                            is used solely for Tax purposes in absence of valid Social Security Number (SSN). Having an
                            ITIN for your Spouse/Dependents can make you eligible to claim them on your Tax Return and
                            claim certain benefits including deductions/Credits thereby reducing your overall Tax
                            Liability.</p>
                        <p>Universal Financials can assist and guide you in timely filing of Form W-7(ITIN Application)
                            along with requisite supporting documents for obtaining ITIN. For more information on our
                            ITIN Services, please feel free to contact us.</p>
                    </div>

                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>FBAR & FATCA FILING <span>COMPLIANCE</span></h2>
                        </div>
                        <div class="border"></div>
                        <p>Universal Financials offer FBAR filing and FATCA Filing Services.</p>

                        <p><b>Foreign Bank Account Reporting (FBAR)</b> is a reporting requirement that gives an idea to IRS as
                            to what money of an US Person is held overseas.</p>

                        <p>Any U.S. Person (such as U.S. Resident) with financial interest or having signing authority
                            on a foreign account with balance of $10,000 or more at any point during the tax year must
                            file FBAR even if such balance of $10,000 is held for a single day. The threshold of $10,000
                            includes the cumulative balance in all of the eligible accounts.</p>

                        <p>Such eligible accounts include – Foreign Bank Accounts, Stocks or Securities held in
                            financial accounts at a foreign financial institution, Foreign Mutual Funds and Foreign
                            issued Life Insurance or Annuity Contracts.</p>

                        <p>The FBAR filing process is different from regular income Tax filing though the due date to
                            file FBAR is same as that of Federal Income Tax Filing due date.</p>

                        <p>The <b>Foreign Account Tax Compliance Act (FATCA) </b> filing requirement is in addition to FBAR
                            Filing.</p>

                        <p>FATCA requires certain U.S. Taxpayers who hold foreign financial assets with an aggregate
                            value of more than the reporting threshold (at least $50,000) to report information about
                            those assets on Form 8938, which must be attached to the Taxpayer’s Federal Income Tax
                            Return. The reporting threshold is higher for certain individuals, including married
                            taxpayers filing a joint Federal Income Tax Return and certain Taxpayers living in a foreign
                            country.</p>

                        <p>Foreign Financial Assets include foreign financial accounts and foreign non-account assets
                            held for investment (as opposed to held for use in a trade or business), such as foreign
                            stock and securities, foreign financial instruments, contracts with non-U.S. persons, and
                            interests in foreign entities.</p>

                        <p>Any failure to file Form 8938 even after meeting all the said conditions will attract severe
                            monetary penalties.</p>

                        <p>For any query on FBAR and/or FATCA filing compliance requirement, do get in touch with
                            us.</p>
                        <p><a href="/mytax/#/register" class="btn btn-primary">Get Started</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
