<section id="resources" class="strategy-area ptb-100 bg-F7F5F4">
    <div class="container-fluid">
        <div class="container">
            <div class="section-title">
                <h2>Our <span>Resources</span></h2>
                <p>Universal Financials understands the value of your time and hence have provided all the relevant links at one place so that you can get all information you wish to know about your Taxes.</p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <img src="assets/img/resources.jpg" alt="image">
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="strategy-content ptb-70">

                        <ul class="features-list">
                            <li> <i class="fa fa-check"></i> To Visit <b>Internal Revenue Service</b> website, please click <a href="http://www.irs.gov/" target="_blank"><b>here</b></a></li>
                            <li> <i class="fa fa-check"></i> To check your <b>Federal Refund status</b>, please click <a href="http://www.irs.gov/Refunds/Where%27s-My-Refund-It%27s-Quick,-Easy,-and-Secure.?portlet=106" target="_blank"><b>here</b></a></li>
                            <li> <i class="fa fa-check"></i> To check your <b>State Refund status</b>, please select the State below and click GO.</li>
                            <li style="list-style-type:none; height: 40px;">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select id="usstate" name="usstate" class="form-control" [(ngModel)]="selectedLink">
                                                <option value="">Please Select State</option>
                                                <option value="https://myalabamataxes.alabama.gov/_/#1">Alabama</option>
                                                <option value="https://atap.arkansas.gov/_/#1">Arkansas</option>
                                                <option value="https://www.aztaxes.gov/default.aspx?target=CheckRefund">Arizona</option>
                                                <option value="https://www.ftb.ca.gov/online/refund/index.asp">California</option>
                                                <option value="https://www.colorado.gov/revenueonline/_/#2">Colorado</option>
                                                <option value="https://dorweb.revenue.delaware.gov/refinq/">Connecticut</option>
                                                <option value="https://dorweb.revenue.delaware.gov/refinq/">Delaware</option>
                                                <option value="https://gtc.dor.ga.gov/_/#2">Georgia</option>
                                                <option value="https://tax.ehawaii.gov/hoihoi/refund.html;jsessionid=E60532CB2103ABED20B39B48085B4625.www01">Hawaii</option>
                                                <option value="https://www.idr.iowa.gov/wheresmyrefund/login.aspx">Iowa</option>
                                                <option value="https://www.accessidaho.org/secure/istc/refund/status.html">Idaho</option>
                                                <option value="https://www.revenue.state.il.us/app/refund/servlet/RefundStatus/?_CWSKEY=9053810081353052158453&amp;_CWSREQ=00439">Illinois</option>
                                                <option value="https://secure.in.gov/apps/dor/tax/refund/ ">Indiana</option>
                                                <option value="https://www.kdor.org/refundstatus/default.asp">Kansas</option>
                                                <option value="https://esweb.revenue.louisiana.gov/WheresMyRefund/">Louisiana</option>
                                                <option value="https://wfb.dor.state.ma.us/webfile/wsi/">Massachusetts</option>
                                                <option value="https://interactive.marylandtaxes.com/INDIV/refundstatus/home.aspx">Maryland</option>
                                                <option value="https://portal.maine.gov/refundstatus/Registration.do;jsessionid=19863803eead04737f1aa2ee4bdb86ef0f3ad2e378c285479548db9eacc7f7ae.e34Mb38PchyPbi0Lai0">Maine</option>
                                                <option value="https://treas-secure.treas.state.mi.us/eservice_enu/start.swe?SWECmd=Start&amp;SWEHo=treas-secure.treas.state.mi.us">Michigan</option>
                                                <option value="https://www.mndor.state.mn.us/tp/refund/CA0ip3Y_/#1">Minnesota</option>
                                                <option value="https://dors.mo.gov/tax/taxinq/welcome.jsp">Missouri</option>
                                                <option value="https://tap.dor.ms.gov/_/#1">Mississippi</option>
                                                <option value="https://tap.dor.mt.gov/_/#2">Montana</option>
                                                <option value="https://eservices.dor.nc.gov/wheresmyrefund/SelectionServlet">North Carolina</option>
                                                <option value="https://secure.apps.state.nd.us/tax/refundstatus/refundstatus.htm">North Dakota</option>
                                                <option value="http://www.revenue.ne.gov/refund/refunds.html">Nebraska</option>
                                                <option value="http://www.state.nj.us/treasury/taxation/emailfaqs.shtml#ITRates">New Jersey</option>
                                                <option value="https://www8.tax.ny.gov/PRIS/prisStart">New York</option>
                                                <option value="https://www.tax.state.oh.us/PROD/WheresMyRefundWeb/wheresmyrefund.jsp">Ohio</option>
                                                <option value="http://www.tax.ok.gov/refund.html">Oklahoma</option>
                                                <option value="https://secure.dor.state.or.us/refund/refund.cfm">Oregon</option>
                                                <option value="https://www.doreservices.state.pa.us/pitservices/wheresmyrefund.aspx">Pennsylvania</option>
                                                <option value="https://www.sctax.org/refundstatus/refund.aspx">South Carolina</option>
                                                <option value="https://tap.tax.utah.gov/TaxExpress/_/#1">Utah</option>
                                                <option value="https://www.individual.tax.virginia.gov/VTOL/IndRefundStatus.seam?CFID=9405316&amp;CFTOKEN=16529769">Virginia</option>
                                                <option value="https://secure.vermont.gov/TAX/refund/">Vermont</option>
                                                <option value="https://ww2.revenue.wi.gov/RefundInquiry/application">Wisconsin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2 mt5"><a (click)="goToLink()" id="goToSite" class="btn btn-xs btn-primary">Go</a></div>
                                </div>

                            </li>
                            <li> <i class="fa fa-check"></i> To pay <b>Federal Tax Due amount</b>, please click <a href="https://www.eftps.gov/eftps/index.jsp" target="_blank"><b>here</b></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>

