import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indian-individual-services',
  templateUrl: './indian-individual-services.component.html',
  styleUrls: ['./indian-individual-services.component.scss']
})
export class IndianIndividualServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
