<section id="services" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>Universal Financials offers a wide range of Financial Services to cater to the financial requirements of diverse customers. Currently, our Services are concentrated in two top most countries of the world - The United States of America and India. We are putting incessant efforts to expand our base to other countries and to penetrate into other financial segments to soon become a global financial services provider.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2 class="fs40">US Individual  <span>Services</span></h2>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p-->
                    </div>
                    <ul class="features-list features-list-cust ulCustHover">
                        <li class="feListSub" (click)="openUsTaxFiling()"><i class="fas fa-angle-right"></i>US Tax Filing Services</li>
                        <li class="feListSub" (click)="openUsTaxRepresentation()"><i class="fas fa-angle-right"></i>US Tax Representation</li>
                        <li class="feListSub" (click)="openITINAndFBARFilingServices()"><i class="fas fa-angle-right"></i>ITIN and FBAR Filing</li>
                    </ul>
                    <!--ul class="features-list features-list-cust ulCustHover">
                        <li><i class="fas fa-check"></i><b>US Corporate Services</b></li>
                        <li class="feListSub" (click)="openUsIncorporations()"><i class="fas fa-angle-right"></i>US Incorporations</li>
                        <li class="feListSub" (click)="openUsAccountingBookKeeping()"><i class="fas fa-angle-right"></i>US Accounting & Book Keeping</li>
                        <li class="feListSub" (click)="openUsPayrollComponent()"><i class="fas fa-angle-right"></i>US Payroll Services</li>
                    </ul-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="./assets/img/about.jpg" alt="about">

                    <div class="video-btn">
                        <a href="https://www.youtube.com/watch?v=MrkvqhuSKl8?rel=0" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="./assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <!--h4>Highly Creative Solutions</h4-->
                        <h2 class="fs40">US Corporate  <span>Services</span></h2>
                        <!--h2 class="fs23">Indian  <span>Financial Services</span></h2-->
                        <!--h2>INDIAN  <span>FINANCIAL SERVICES</span></h2-->
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p-->
                    </div>
                    <ul class="features-list ulCustHover">
                        <li class="feListSub" (click)="openUsIncorporations()"><i class="fas fa-angle-right"></i>US Incorporations</li>
                        <li class="feListSub" (click)="openUsAccountingBookKeeping()"><i class="fas fa-angle-right"></i>US Accounting & Book Keeping</li>
                        <li class="feListSub" (click)="openUsPayrollComponent()"><i class="fas fa-angle-right"></i>US Payroll Services</li>
                        <!--li class="feListSub" (click)="openINDIndividualCorporateTaxes()"><i class="fa fa-check"></i>Indian Individual & Corporate Taxes</li>
                        <li class="feListSub" (click)="openINDIncorporations()"><i class="fa fa-check"></i>Indian Incorporations</li>
                        <li class="feListSub" (click)="openINDAccountingServices()"><i class="fa fa-check"></i>Indian Accounting Services</li>
                        <li class="feListSub" (click)="openINDPayrollServices()"><i class="fa fa-check"></i>Indian Payroll Services</li>
                        <li class="feListSub" (click)="openINDOtherServices()"><i class="fa fa-check"></i>Other Services</li-->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="./assets/img/shape1.png" alt="image"></div>
</section>
