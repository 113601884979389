<section id="about" class="welcome-area article">
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                About Us
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Article <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <h4>{{rowData.title}}</h4>
            <p>&nbsp;</p>
            <div class="runningContent" [innerHTML]="rowData.description"></div>
            <p>&nbsp;</p>
        </div>
    </div>
</section>
