import {Component, OnInit} from '@angular/core';
import {AboutFullContentComponent} from '../about-full-content/about-full-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DefaultModalboxComponent} from '../default-modalbox/default-modalbox.component';
import {NewyearModalboxComponent} from '../newyear-modalbox/newyear-modalbox.component';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
        // this.defaultNewYearModal();
    }

    defaultModal() {
        const modalRef = this.modalService.open(DefaultModalboxComponent, {
            size: 'lg',
            windowClass: 'covidHomeModal'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    defaultNewYearModal() {
        const modalRef = this.modalService.open(NewyearModalboxComponent, {
            size: 'lg',
            windowClass: 'newYearModal'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

}
