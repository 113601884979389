export const apiConfig = {
    contactUs: '/v2/contact-us',
    referFriend: '/v2/refer-friend',
    wantusCall: '/v2/wantus-call',
    blog: '/v2/blog',
    scheduleTimeList: [
        {id: '7.00 AM CST', name: '7.00 AM CST'},
        {id: '7.30 AM CST', name: '7.30 AM CST'},
        {id: '8.00 AM CST', name: '8.00 AM CST'},
        {id: '8.30 AM CST', name: '8.30 AM CST'},
        {id: '9.00 AM CST', name: '9.00 AM CST'},
        {id: '9.30 AM CST', name: '9.30 AM CST'},
        {id: '10.00 AM CST', name: '10.00 AM CST'},
        {id: '10.30 AM CST', name: '10.30 AM CST'},
        {id: '11.00 AM CST', name: '11.00 AM CST'},
        {id: '11.30 AM CST', name: '11.30 AM CST'},
        {id: '12.00 NOON CST', name: '12.00 NOON CST'},
        {id: '12.30 PM CST', name: '12.30 PM CST'},
        {id: '1.00 PM CST', name: '1.00 PM CST'},
        {id: '1.30 PM CST', name: '1.30 PM CST'},
        {id: '2.00 PM CST', name: '2.00 PM CST'},
        {id: '2.30 PM CST', name: '2.30 PM CST'},
        {id: '3.00 PM CST', name: '3.00 PM CST'},
        {id: '3.30 PM CST', name: '3.30 PM CST'},
        {id: '4.00 PM CST', name: '4.00 PM CST'},
        {id: '4.30 PM CST', name: '4.30 PM CST'},
        {id: '5.00 PM CST', name: '5.00 PM CST'},
        {id: '5.30 PM CST', name: '5.30 PM CST'},
        {id: '6.00 PM CST', name: '6.00 PM CST'},
        {id: '6.30 PM CST', name: '6.30 PM CST'},
        {id: '7.00 PM CST', name: '7.00 PM CST'},
        {id: '7.30 PM CST', name: '7.30 PM CST'},
        {id: '8.00 PM CST', name: '8.00 PM CST'},
        {id: '8.30 PM CST', name: '8.30 PM CST'},
        {id: '9.00 PM CST', name: '9.00 PM CST'},
        {id: '9.30 PM CST', name: '9.30 PM CST'},
        {id: '10.00 PM CST', name: '10.00 PM CST'}
    ],
    services: [
        {id: 'Individual Tax Return'},
        {id: 'Business Tax Return'},
        {id: 'Tax Planning'},
        {id: 'FBAR - Foreign Bank Account Report Filing'},
        {id: 'Notice Representation'},
        {id: 'Business Entity Formation'},
        {id: 'Non Profit Entity Formation'},
        {id: 'Bookkeeping Service'},
        {id: 'Payroll Processing'},
        {id: 'India Tax Return'},
        {id: 'PAN Card'},
        {id: 'ITIN Services'}
    ]
};
