<app-navbar></app-navbar>
<app-topfixednav></app-topfixednav>
<div class="wrapperDiv">
    <div class="main-banner item-bg-one jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-text">

                        <h4>&nbsp;</h4>
                        <h1>Customized Tax Planning for <span>Individuals</span> and Corporates</h1>
                        <!--p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p-->
                        <a href="#services" class="btn btn-primary custbtn">View Services</a>
                        <!--a href="#referafriend" class="btn btn-primary custbtn">Refer A Friend</a>
                        <a href="#wantus-call" class="btn btn-primary view-work custbtn">Request A Call</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="suggestPost">
        <a href="#referafriend" class="custbtn">Refer A Friend</a>
    </div>
    <div id="reqtocall">
        <a href="#wantus-call" class="custbtn">Request A Call</a>
    </div>
    <app-about></app-about>


    <app-services></app-services>

    <app-refer-a-friend></app-refer-a-friend>

    <app-resources></app-resources>

    <app-careers></app-careers>

    <app-contact></app-contact>

    <app-wantus-call></app-wantus-call>

    <app-IndianFinancialServices></app-IndianFinancialServices>

</div>
