import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-default-modalbox',
    templateUrl: './default-modalbox.component.html',
    styleUrls: ['./default-modalbox.component.scss']
})
export class DefaultModalboxComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }
    close() {
        this.activeModal.close();
    }


}
