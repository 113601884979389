import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
    selectedLink: string;
    constructor() {
    }

    ngOnInit() {
        this.selectedLink = '';
    }

    goToLink() {
        if (this.selectedLink) {
            window.open(this.selectedLink, '_blank');
        }
    }

}
