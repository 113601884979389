import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { AboutFullContentComponent } from './components/about-full-content/about-full-content.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import {ReferAFriendComponent} from './components/common/ReferAFriend/ReferAFriend.component';
import {ResourcesComponent} from './components/common/resources/resources.component';
import {CareersComponent} from './components/common/careers/careers.component';
import {TopfixednavComponent} from './components/common/topfixednav/topfixednav.component';
import {FullPageNavbarComponent} from './components/common/full-page-navbar/full-page-navbar.component';
import {ServicesFullUsTaxFilingComponent} from './components/services-full-us-tax-filing/services-full-us-tax-filing.component';
import {IndianIndividualServicesComponent} from './components/indian-individual-services/indian-individual-services.component';
import {HomeOneComponent} from './components/home-one/home-one.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ServicesFullUsTaxRepresentationComponent} from './components/services-full-us-tax-representation/services-full-us-tax-representation.component';
import {ServicesFullUsIncorporationsComponent}
from './components/services-full-us-incorporations/services-full-us-incorporations.component';
import {ServicesFullUsAccountingBookKeepingComponent} from './components/services-full-us-accounting-book-keeping/services-full-us-accounting-book-keeping.component';
import {ServicesFullUsPayrollComponent} from './components/services-full-us-payroll/services-full-us-payroll.component';
import {ServicesFullINDIndividualCorporateTaxesComponent} from './components/services-full-IND-Individual-corporate-taxes/services-full-IND-Individual-corporate-taxes.component';
import {ServicesFullINDIncorporationsComponent} from './components/services-full-IND-Incorporations/services-full-IND-Incorporations.component';
import {ServicesFullINDAccountingServicesComponent} from './components/services-full-IND-accounting-services/services-full-IND-accounting-services.component';
import {ServicesFullINDPayrollServicesComponent} from './components/services-full-IND-payroll-services/services-full-IND-payroll-services.component';
import {ServicesFullINDOtherServicesComponent} from './components/services-full-IND-other-services/services-full-IND-other-services.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MastersService} from './shared/services/masters.service';
import {HttpClientModule} from '@angular/common/http';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {WantusCallComponent} from './components/common/wantus-call/wantus-call.component';
import {FooterFullDisclaimerComponent} from './components/footer-full-Disclaimer/footer-full-Disclaimer.component';
import {FooterFullTermsConditionsComponent} from './components/footer-full-Terms-Conditions/footer-full-Terms-Conditions.component';
import {FooterFullPrivacyPolicyComponent} from './components/footer-full-Privacy-Policy/footer-full-Privacy-Policy.component';
import {FooterFullReferralPolicyComponent} from './components/footer-full-Referral-Policy/footer-full-Referral-Policy.component';
import {DefaultModalboxComponent} from './components/default-modalbox/default-modalbox.component';
import {IndianFinancialServicesComponent} from './components/common/IndianFinancialServices/IndianFinancialServices.component';
import {ArticleContentComponent} from './components/article-content/article-content.component';
import {ServicesFullITINAndFBARFilingComponent} from './components/services-full-ITIN-and-FBAR-Filing/services-full-ITIN-and-FBAR-Filing.component';
import {NoRightClickModule} from './no-right-click/no-right-click.module';
import {NewyearModalboxComponent} from './components/newyear-modalbox/newyear-modalbox.component';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        FullPageNavbarComponent,
        WelcomeComponent,
        AboutComponent,
        WhoWeAreComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        FeedbackComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        AboutFullContentComponent,
        ServicesFullUsTaxFilingComponent,
        ServicesTwoComponent,
        ReferAFriendComponent,
        ResourcesComponent,
        CareersComponent,
        TopfixednavComponent,
        IndianIndividualServicesComponent,
        HomeOneComponent,
        ServicesFullUsTaxRepresentationComponent,
        ServicesFullUsIncorporationsComponent,
        ServicesFullUsAccountingBookKeepingComponent,
        ServicesFullUsPayrollComponent,
        ServicesFullINDIndividualCorporateTaxesComponent,
        ServicesFullINDIncorporationsComponent,
        ServicesFullINDAccountingServicesComponent,
        ServicesFullINDPayrollServicesComponent,
        ServicesFullINDOtherServicesComponent,
        ConfirmationComponent,
        WantusCallComponent,
        FooterFullDisclaimerComponent,
        FooterFullTermsConditionsComponent,
        FooterFullPrivacyPolicyComponent,
        FooterFullReferralPolicyComponent,
        DefaultModalboxComponent,
        NewyearModalboxComponent,
        IndianFinancialServicesComponent,
        ArticleContentComponent,
        ServicesFullITINAndFBARFilingComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NoRightClickModule
    ],
    providers: [
        MastersService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
