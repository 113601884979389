<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span>
    </div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>US Individual <span>Services</span></h2>
                        </div>
                        <div class="border"></div>
                        <h6 class="usindividualservices">Tax Planning, Preparation &amp; Filing Services :</h6>
                        <p>Universal Financials is specialized in offering Tax Planning, Preparation and Filing of US
                            Individual Income Tax Return services for US Citizens, Green card Holders, Expatriates from
                            other countries who have come to the US on various reasons such as Employment and/or Study
                            purposes including the Non-Resident Aliens who have a requirement of filing the Tax Returns
                            within the United States of America..</p>
                        <ul class="normalul">
                            <li>Our Tax Filing Service include Preparation &amp; Filing of Federal Returns such as Form
                                1040/1040NR along with all applicable Schedules and Supporting.
                            </li>
                            <li>All Taxable State Returns.</li>
                            <li>Amendment Returns (Form 1040X).</li>
                        </ul>
                        <p>One of the unique features of our Taxation Service includes our thorough knowledge on
                            different Tax Laws and the identification of various Tax Planning tools for the benefit of
                            our clients. Most of our Indian clients who have been to the United States on Employment and
                            Student Visas such as H1, H1B, L1 &amp; F1 Visas have benefitted much from our Tax Planning
                            Service. We have also gained adequate expertise in offering customized long term tax
                            planning solutions including Retirement Planning and Estate Planning to US Citizens and
                            Green Card holders.</p>
                        <p>Our expertise, dedication and commitment towards offering unique, flawless and incessant Tax
                            Planning and Filing Services is clearly demonstrated from our initiative of setting up a
                            separate wing exclusively for conducting Research on all Tax matters. We have a dedicated
                            team of Tax Researchers who are always involved in conducting a detailed research on various
                            Tax Laws and interpreting the same for applicability to our clients' scenario. Our constant
                            knowledge upgradation through incessant research makes the tax filing process simple and
                            quick. Our tailor-made process with continuous improvement is facilitating us to provide the
                            Quality services to our esteemed client like you. In addition to this, our exceptional
                            customer service and competitive price makes us unique.</p>

                        <div class="ques">Do you know that you can re-file an already filed Tax Return? Have you
                            forgotten claiming something on your already filed Tax Return??
                        </div>
                        <p>No problem.We will help you file an Amended Tax Return easily which again is one of our
                            unique services. By availing this service, you can know the real benefits available to a
                            Taxpayer like you which you missed claiming on your originally filed Tax Return due to lack
                            of knowledge. Claiming such benefits may result in higher refunds making availability of
                            more money for your family needs.</p>
                        <p><b>Filing a Tax Return has never been so simple.</b></p>
                        <p style="padding-top:0px">Our tailor made process makes the tax filing look very simple. Here
                            is the five step process:</p>
                        <ul class="normalul" style="margin-top:5px;">
                            <li>Give the requested information and Upload necessary documents</li>
                            <li>Find the Tax Synopsis at your desk</li>
                            <li>Make the payment</li>
                            <li>Check the Tax Return and give confirmation</li>
                            <li>We will file the Return on your Behalf.</li>
                        </ul>
                        <p>Provide all the requested Information/documents and File your tax returns in A DAY!!</p>
                        <div class="ques">Why should you choose Universal Financials?</div>
                        <p>At the outset, we are here to serve to your satisfaction, and our caption says that
                            'Delivering the Expectations'. Below are some of our key qualities:</p>
                        <ul class="normalul">
                            <li>Smooth and secured way of collecting information.</li>
                            <li>Preparation of Tax Returns after a complete analysis of Tax situation.</li>
                            <li>Maintaining Accuracy and Error-Free Return processing with multi-level Review of
                                Returns.
                            </li>
                            <li>Excellent Customer Support with round the clock accessibility.</li>
                            <li>Stringent set of Standard Level Agreements (SLAs) and Zero compromise on 'Turn Around
                                Time' (TATs).
                            </li>
                            <li>Electronic/Online Filing of Tax Returns (if applicable).</li>
                            <li>After Sales Service - Follow up with Federal &amp; State Revenue Departments for Return
                                status and timely issue of Refunds.
                            </li>
                            <li>Our Rich Experience and Economical Pricing.</li>
                        </ul>
                        <p><b>When you think of Filing a Tax Return, think of Universal Financials!!!</b></p>
                        <p><a href="/mytax/#/register" class="btn btn-primary">Get Started</a></p>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
