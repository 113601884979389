<section id="careers" class="strategy-area ptb-100">
    <div class="container-fluid">
        <div class="container">
            <div class="section-title">
                <h2>Universal <span>Careers</span></h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="strategy-content custpadding custPTag">
                        <p>Universal Financials is entrusted in building and expanding its organization with qualitative, well trained work force. Keeping this in mind, we always encourage the talented professionals to be part of our team.</p>
                        <p>If you feel that you have the right talent, Universal Financials is the right place to work with. You may forward your profile to <a href="mailto:hr@metafinconsulting.com"><b>hr@metafinconsulting.com</b></a> to get considered for our existing and future openings.</p>
                        <p>We provide bountiful of opportunities to improve your skills which you attained through your education and experience.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="strategy-image">
                        <img src="assets/img/career.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="./assets/img/shape1.png" alt="image"></div>
</section>

