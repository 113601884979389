
<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand" routerLink="/"><img src="../assets/img/logoold.png" alt=""></a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home">HOME</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">ABOUT US</a></li>
                <li class="nav-item"><a class="nav-link" href="#services">SERVICES</a></li>
                <li class="nav-item"><a class="nav-link" href="#referafriend">REFER A FRIEND</a></li>
                <li class="nav-item"><a class="nav-link" href="#resources">RESOURCES</a></li>
                <li class="nav-item"><a class="nav-link" href="#careers">CAREERS</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">CONTACT</a></li>
            </ul>

        </div>
    </div>

</nav>

