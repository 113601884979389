<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>US Payroll<span> Services</span></h2>
                        </div>
                        <div class="border mt25 mb15    "></div>
                        <p>When you are seriously involved in managing your business and making business expansion plans, all other tasks look small and you feel like passing such tasks to some experienced hands. One such task could be the Payroll Processing of your employees and maintenance of all records as per the compliance requirements which is a tedious job keeping in view of the various Federal and State/Local Laws. It is very important to comply with all the legal compliances failing which could lead to unnecessary tensions.</p>
                        <p>As you are into the business in achieving the mission and vision, why get sidelined with the works like Payroll etc.., give us the opportunity of maintaining your accounts. Universal Financials can help you in paying your employees and paying all the taxes in timely manner to the concerned departments.</p>
                        <p><b>We process your employee payrolls and pay them as per your specifications. Following are some of the important services we offer you in keeping your payroll records up to date:</b></p>
                        <ul class="normalul">
                            <li>Running the Payroll in timely manner</li>
                            <li>Paying all the Taxes like FICA, FUTA, SUTA in time</li>
                            <li>Suggesting the best tax deductions available under various Tax Laws</li>
                            <li>Generating the Paystubs periodically</li>
                            <li>Issue of W2s, 1099s etc.</li>
                            <li>Filing the quarterly and annual Payroll Tax Returns with all the relevant Departments</li>
                            <li>Dedicated Customer Support</li>
                        </ul>
                        <p>Our competitive pricing is highly appreciated by most of our clients. We also provide direct deposits for your employees.</p>


                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
