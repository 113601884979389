<section id="wantus-call" class="contact-area ptb-100 ">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Select Time for  <span>company to call</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="dataForm" (submit)="onDataSubmit()" class="needs-validation">
                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" formControlName="name" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.invalid, 'is-valid': submitted && !f.name.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.name.invalid || f.name.errors?.required)">Please Enter
                                        Name</small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Email ID</label>
                                    <input type="email" formControlName="email" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.invalid, 'is-valid': submitted && !f.email.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.email.invalid || f.email.errors?.required)">Please
                                        Enter Email</small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Contact Number</label>
                                    <input type="text" formControlName="contact" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.contact.invalid, 'is-valid': submitted && !f.contact.invalid }">
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.contact.invalid || f.contact.errors?.required)">Please
                                        Enter Contact Number</small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Select Date</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="mm/dd/yyyy" formControlName="selectDate" id="selectDate"
                                               dateFormat="MMM/dd/yyyy" (click)="dselectDate.toggle()" readonly
                                               [navigation]="navigation" ngbDatepicker #dselectDate="ngbDatepicker"
                                               [minDate]="todayDate"
                                               [maxDate]="{year: 2024, month: 12, day: 31}"
                                               [ngClass]="{ 'is-invalid': submitted && f.selectDate.invalid, 'is-valid': submitted && !f.selectDate.invalid }">
                                        <div class="input-group-append">
                                            <div class="input-group-text" (click)="dselectDate.toggle()">
                                                <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.selectDate.invalid || f.selectDate.errors?.required)">Please
                                        Select Select Date</small>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Select Time</label>
                                    <select id="selectTime" formControlName="selectTime" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.selectTime.invalid, 'is-valid': submitted && !f.selectTime.invalid }">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let row of scheduleTimeList;" [ngValue]="row.id">{{row.name}}</option>
                                    </select>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.selectTime.invalid || f.selectTime.errors?.required)">Please Select Time</small>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>Services</label>
                                    <select id="services" formControlName="services" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.services.invalid, 'is-valid': submitted && !f.services.invalid }">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let row of services;" [ngValue]="row.id">{{row.id}}</option>
                                    </select>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.services.invalid || f.services.errors?.required)">Please
                                        Select Services</small>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="form-group">
                                    <label>Comments</label>
                                    <textarea class="form-control" formControlName="comments" cols="30" rows="2"
                                              [ngClass]="{ 'is-invalid': submitted && f.comments.invalid, 'is-valid': submitted && !f.comments.invalid }"></textarea>
                                    <small class="form-text text-danger"
                                           *ngIf="submitted && (f.comments.invalid || f.comments.errors?.required)">Please Enter Comments</small>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
