import { Component, OnInit } from '@angular/core';
import {ServicesFullUsTaxFilingComponent} from '../../services-full-us-tax-filing/services-full-us-tax-filing.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FooterFullDisclaimerComponent} from '../../footer-full-Disclaimer/footer-full-Disclaimer.component';
import {FooterFullTermsConditionsComponent} from '../../footer-full-Terms-Conditions/footer-full-Terms-Conditions.component';
import {FooterFullPrivacyPolicyComponent} from '../../footer-full-Privacy-Policy/footer-full-Privacy-Policy.component';
import {FooterFullReferralPolicyComponent} from '../../footer-full-Referral-Policy/footer-full-Referral-Policy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(private modalService: NgbModal) {
    }

  ngOnInit() {
  }
    openFullDisclaimer() {
        const modalRef = this.modalService.open(FooterFullDisclaimerComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openFullTermsConditions() {
        const modalRef = this.modalService.open(FooterFullTermsConditionsComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openFullPrivacyPolicy() {
        const modalRef = this.modalService.open(FooterFullPrivacyPolicyComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openFullReferralPolicy() {
        const modalRef = this.modalService.open(FooterFullReferralPolicyComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

}
