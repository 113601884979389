import {Component, OnInit} from '@angular/core';
import {AboutFullContentComponent} from '../../about-full-content/about-full-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {MastersService} from '../../../shared/services/masters.service';
import {ArticleContentComponent} from '../../article-content/article-content.component';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    articles: any = [];
    constructor(private modalService: NgbModal, private mastersService: MastersService) {
    }

    ngOnInit() {
        this.blog();
    }

    openAbout() {
        const modalRef = this.modalService.open(AboutFullContentComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    blog() {
        this.mastersService.blog()
            .subscribe((data) => {
                this.articles = data.articles;
            }, (error) => {
                console.log(error);
            });
    }

    showArticle(row: any) {
        const modalRef = this.modalService.open(ArticleContentComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        modalRef.componentInstance.rowData = row;
    }
}
