<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>Indian Accounting and<span> Book Keeping Services</span></h2>
                        </div>
                        <div class="border mt25 mb15    "></div>
                        <p>The profitability position of a business can be known only when the Books of Accounts are properly maintained. It is also a statutory and compliance requirement to maintain proper accounts in a prescribed format as per the applicable Rules and Laws. Universal Financials can be of help to you in proper maintenance of books of accounts. We record all the transactions you do by keeping the books of accounts on daily/periodical basis. We collect the transactions made by you on daily/periodical basis and update you with all the customized reports.</p>
                        <p>Universal Financials has a team of professionals including CAs &amp; CWAs who possess vast expertise in preparation of accounts for all types and size of business. We adopt the "value added" approach thereby ensuring higher standards at reasonable costs. Our Accounting Services include:</p>
                        <ul class="usindividual">
                            <li>Setting up of an Accounting System in an organization.</li>
                            <li>Assistance in maintenance of necessary Books of Accounts.</li>
                            <li>Preparation of periodical financial statements</li>
                            <li>Finalization of accounts on a periodical basis.</li>
                            <li>Furnishing necessary MIS Reports as required by you.</li>
                            <li> Liaison with the Auditors on Finalization of Accounts.</li>
                            <li>Supervision of accounting transactions of a company to ensure compliance with best accounting practices.</li>
                            <li>Recommending and implementing suitable Internal control systems and procedures to suit the requirements of the company.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

