<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>Other <span> Services</span></h2>
                        </div>
                        <div class="border mt25 mb15"></div>
                        <p>We offer certain other services as stated below:</p>
                        <h6>Audit Services:</h6>
                        <p>Universal Financials consists a team of highly experienced and qualified Chartered Accountants &amp; Cost Accountants possessing audit experience in various Entities including Private and Public Companies, Banks, etc. We are specialized in providing tailor made, value added and focused accounting and audit services to enable companies to maintain proper Books of Accounts fulfilling all the statutory and legal requirements.</p>
                        <p><b>Our Audit Services include the following:-</b></p>
                        <ul class="normalul">
                            <li>Internal Audit</li>
                            <li>Statutory Audit under Companies Act,1956</li>
                            <li>Tax Audit under Income Tax Act, 1961</li>
                        </ul>
                        <br>
                        <div class="border mb15"></div>
                        <h6>Registrations &amp; Filing under VAT, Service Tax, PF, ESI and Trademarks:</h6>
                        <p>Universal Financials can assist you to liaison with various government departments on various issues including undertaking of Registration formalities under VAT, Service Tax Act, Employee Provident Fund Scheme (EPFS), Employee State Insurance Corporation (ESIC), Trademarks, etc. We also assist in Filing of Periodical Returns (Monthly/Quarterly/Half-Yearly/Yearly) as required under the respective governing Laws.</p>
                        <p>Kindly note that we provide customized services and hence if you have any financial service requirement that is not covered above, you may mail us with your requirement details to <a href="mailto:contact@universalfinancials.com"><b>contact@universalfinancials.com</b></a> and we will get back to you.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

