<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>Indian Payroll <span> Services</span></h2>
                        </div>
                        <div class="border mt25 mb15    "></div>
                        <p>It is well known fact that paying the employees in an accurate manner and maintaining the compliances with the concerned legal Departments is a challenging task. As you are into the business in achieving the mission and vision, why get sidelined with the works like Payroll etc.., give us the opportunity of maintaining your accounts. We can be of assist to you in Payroll processing, paying your employees and handling of statutory requirement including filing of necessary Returns in a timely manner.</p>
                        <p>Our Team with experience of more than 10 years in the field of Payroll processing can provide you with much needed relaxation from all manpower related issues enabling you to fully focus on growing your business and other high end activity without having manpower constraints.</p>
                        <p>We provide the following range of services:-</p>
                        <ul class="usindividual">
                            <li>Structuring of employee salaries.</li>
                            <li>Processing of monthly payroll and generating of pay slips.</li>
                            <li>Sending of Pay slips through Email.</li>
                            <li>Facilitating direct deposit of Salaries to Employee Bank Accounts.</li>
                            <li>Remittance of monthly tax deductions to appropriate authorities.</li>
                            <li>E-filing of TDS Returns and filing of Statutory Returns.</li>
                            <li>Providing MIS Reports.</li>
                            <li>Preparation of year end salary certificates.</li>
                            <li>Tax Consultation.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

