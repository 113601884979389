import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-services-full-content',
    templateUrl: './footer-full-Referral-Policy.html',
    styleUrls: ['./footer-full-Referral-Policy.component.scss']
})
export class FooterFullReferralPolicyComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }
    close() {
        this.activeModal.close();
    }


}
