import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ServicesFullUsTaxFilingComponent} from '../../services-full-us-tax-filing/services-full-us-tax-filing.component';
import {ServicesFullUsTaxRepresentationComponent} from '../../services-full-us-tax-representation/services-full-us-tax-representation.component';
import {ServicesFullUsIncorporationsComponent} from '../../services-full-us-incorporations/services-full-us-incorporations.component';
import {ServicesFullUsAccountingBookKeepingComponent} from '../../services-full-us-accounting-book-keeping/services-full-us-accounting-book-keeping.component';
import {ServicesFullUsPayrollComponent} from '../../services-full-us-payroll/services-full-us-payroll.component';
import {ServicesFullINDIndividualCorporateTaxesComponent} from '../../services-full-IND-Individual-corporate-taxes/services-full-IND-Individual-corporate-taxes.component';
import {ServicesFullINDIncorporationsComponent} from '../../services-full-IND-Incorporations/services-full-IND-Incorporations.component';
import {ServicesFullINDAccountingServicesComponent} from '../../services-full-IND-accounting-services/services-full-IND-accounting-services.component';
import {ServicesFullINDPayrollServicesComponent} from '../../services-full-IND-payroll-services/services-full-IND-payroll-services.component';
import {ServicesFullINDOtherServicesComponent} from '../../services-full-IND-other-services/services-full-IND-other-services.component';

@Component({
    selector: 'app-IndianFinancialServices',
    templateUrl: './IndianFinancialServices.component.html',
    styleUrls: ['./IndianFinancialServices.component.scss']
})

export class IndianFinancialServicesComponent implements OnInit {

    constructor(private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    openUsTaxFiling() {
        const modalRef = this.modalService.open(ServicesFullUsTaxFilingComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openUsTaxRepresentation() {
        const modalRef = this.modalService.open(ServicesFullUsTaxRepresentationComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openUsIncorporations() {
        const modalRef = this.modalService.open(ServicesFullUsIncorporationsComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openUsAccountingBookKeeping() {
        const modalRef = this.modalService.open(ServicesFullUsAccountingBookKeepingComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openUsPayrollComponent() {
        const modalRef = this.modalService.open(ServicesFullUsPayrollComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openINDIndividualCorporateTaxes() {
        const modalRef = this.modalService.open(ServicesFullINDIndividualCorporateTaxesComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openINDIncorporations() {
        const modalRef = this.modalService.open(ServicesFullINDIncorporationsComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openINDAccountingServices() {
        const modalRef = this.modalService.open(ServicesFullINDAccountingServicesComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openINDPayrollServices() {
        const modalRef = this.modalService.open(ServicesFullINDPayrollServicesComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }

    openINDOtherServices() {
        const modalRef = this.modalService.open(ServicesFullINDOtherServicesComponent, {
            size: 'lg',
            windowClass: 'modalverylarge'
        });
        // modalRef.componentInstance.rowData = rowData;
        // modalRef.componentInstance.fileDetails = this.fileDetails;
        modalRef.result.then(() => {
            // this.all();
        });
    }
}
