<section id="services" class="welcome-area" appNoRightClick>
    <!--div class="pageHeadingWithImg">
        <div class="container">
            <div class="fullPageTitle">
                Services
                <p>US Individual Services</p>
            </div>
        </div>
    </div-->
    <div class="pageModalHeading">Services <span class="close" (click)="close()"> <i class="fa fa-times"></i> </span></div>
    <div class="container">
        <div class="col-sm-12 col-xs-12 pd-0">
            <div class="runningContent">
                <div class="container">
                    <div class="col-sm-12 col-xs-12 pd-0">
                        <div class="section-title modalTitle">
                            <h2>Indian Financial<span> Services</span></h2>
                        </div>
                        <div class="border mt25 mb15    "></div>
                        <h6 class="mb15">Indian Individual &amp; Corporate Taxation Including Service Tax &amp; Vat:</h6>
                        <p>Our Tax Experts can assist you in timely preparation and filing of Individual &amp; Corporate Tax Returns (including Firms, Trusts, etc) and can help you in minimizing the Tax Liability efficiently without compromising on legal matters. Our Services are tailor made to provide Tax Consultations and advices based on the nature and size of the business.</p>
                        <p><b>Our Income Tax Services include:-</b></p>
                        <ul class="usindividual" style="margin-top:7px;">
                            <li>Tax Management and Advisory services to manage tax liability.</li>
                            <li>Calculation of Advance Taxes.</li>
                            <li>Filing of Individual Income Tax Returns.</li>
                            <li>Filing of Corporate Tax Returns, Wealth Tax, Tax Deducted at Source (TDS), etc.</li>
                            <li>Applying and obtaining Permanent Account Numbers (PANs) and Tax deduction Account Numbers (TANs).</li>
                            <li>Preparation &amp; Filing of Returns under VAT and Service Tax Act.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

